<template>
<div>   
	  <XModal v-if="popupData" v-show="visible" :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="false" >
	  <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
	  <div class='appHead'>
             <div style='display: inline-flex; float: left; font-weight: bold; padding-top: 2pt; margin-right: 15pt;'>
             
             </div>
            
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft' v-if="popupData.deal">
            	DEAL Viewer for {{ popupData.deal}}
                </div>	

            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" id='main-body'>
	  
	      <div  style=''>
          <slot name="body">
           
            <div class='mainContent' v-if="allData" style=''>
              
               <div style='position: relative; float: top; width: 100%; height; 80%; padding-right: 15pt; padding-bottom: 8pt;'>
				 Deal: <div :style="getAppStyle()+'cursor: pointer; display: inline-flex; width: 80%'" @click="openEdit('Deal', deal.id)"><b>{{deal.name}}</b></div>
				 <Button  v-if="!discountHeader || !discountHeader.id" style='float:right;' class='button' @click="createDiscountHeader"><mdicon size="12" name="plus"/></button>
				 							  
				 <br/>
				
				 Agency: <div :style="getAppStyle()+'cursor: pointer; display: inline-flex; width: 40%'" @click="openEdit('Client', agency.id)"><b>{{agency.name}}</b></div>
				 Client: <div :style="getAppStyle()+'cursor: pointer; display: inline-flex; width: 40%'" @click="openEdit('Client', client.id)"><b>{{client.name}}</b></div>
				 <br/>
				 Validity:  <div :style="getAppStyle()+'display: inline-flex; width: 25%'">
					<InputDatePick2 :auto='false' v-model='deal.fromDate'/></div> -  <div style='display: inline-flex; width: 25%'>
					<InputDatePick2 :auto='false' v-model='deal.untilDate'/></div>
					<div style="float: right;">
					<Button  v-if="discountHeader && discountHeader.id" class='button' :title="'Create a new discount-folder (structure for channel)'" @click="createDiscountFolder">
						<mdicon size="12" name="plus"/> Discount-Folder
					</button>
					</div>				
				<br/>
				 
				 <div v-if="discountHeader" style='margin-top: 4pt;'>
					 <div>
					 <div v-show="false" class="selectable bold" :style="getAppStyle()+'display: inline-flex; border: 1pt solid grey; padding-bottom: 5pt; padding-top: 5pt; width: 80%'" 
					      @click="openEdit('DiscountScaleHeader', discountHeader.id)">Discount-Header: {{discountHeader.name}}</div>
						  <div :style="getAppStyle()+'display: inline-flex;'">
						  </div>
					 </div>
					 <div v-if="discountHeader" :style="getTableStyle()">	  
						<div :style="getAppStyle()+'display: inline-flex; width: 100%; border-bottom: 1px solid grey;'">
							<div :style="getAppStyle()+'display: inline-flex; width: 70%;'">
								<div class="indent1 bold" :style="getAppStyle()+'display: inline-flex;'">
									<span style="width:180pt; font-size: 8pt;" class="dontWrap">Folder</span>
								</div>
								<div style="width:40pt; font-size: 8pt;">
									by Media
								</div>
								<div style="width:90pt; font-size: 8pt;">
									Condition levels
								</div>					
													
							 </div>
							 <div style="width:90pt; font-size: 8pt;">
								Actions
							 </div>	
						 </div>
						 <div v-for="(fld, fldi) in allData.discountScaleFolders" :key="'f'+fldi">
							<div :style="getAppStyle()+'display: inline-flex; width: 70%;'">
							<div class="selectable indent1 bold" :style="getAppStyle()+'display: inline-flex;'" 
							        @click="openEdit('DiscountScaleFolder', fld.id)"
									@dragstart="startDrag($event, fld)" draggable
									@drop="onDrop($event, fld)"
									@dragover.prevent
									@dragenter.prevent
							    >
								
								<span style="width:180pt; font-size: 10pt;" class="dontWrap">{{allData.mediaMap[fld.id]?allData.mediaMap[fld.id]:"all media"}}</span>
								    
							</div>
							<div style="width:40pt;">
								<div style="margin-top: 0; margin-bottom: 5pt;">
								<app-switch @changed="saveFolder(fld)" :selected=fld.perMedia v-model="fld.perMedia" /> 
								</div>
							</div>
							<span class="steps selectable smallStep" :style="bg4Scale(fld, sc)" v-for="(sc, sci) in allData.discountScales[fld.id]" :key="'d'+fldi+'.'+sci" @click="openFolder(fld.id)">{{format(sc.fromBudget)}}</span>
							</div>
							<Button :title="'Open/Close discount-folder (see all defined levels)'" class='button smallBN' :disabled="!allData.discountScales[fld.id] || !allData.discountScales[fld.id].length" @click="openFolder(fld.id)">
								<mdicon v-if="!fldOpen.includes(fld.id)" size="12" name="chevron-down"/>
								<mdicon v-else size="12" name="chevron-up"/>
							</button>
							<Button :title="'Add a new level'" class='button smallBN' @click="addScale(fld.id)"><mdicon size="12" name="plus"/></button>
							<Button :title="'Delete this folder'" class='button smallBN' @click="removeFolder(fld.id)"><mdicon size="12" name="close"/></button>
							
							<br/>
							<div v-if="fldOpen.includes(fld.id) && allData.discountScales[fld.id]" class="indent2" style='font-size: 8pt;'>
								<div style='display: inline-flex; width: 20%;'>Name</div>
								<div class='scInp'>From</div>
								<div class='scInp'>To</div>
								<div class='scInp'>cash discount</div>
								<div class='scInp'>incentive discount</div>
								<div class='scInp'>pay percentage</div>
								<div></div>
							</div>
							<div v-if="fldOpen.includes(fld.id) && allData.discountScales[fld.id]" class="indent2" 
							     style="border: 1px outset grey; max-height: 240pt; overflow-y: auto;">
								
								<div v-for="(sc, sci) in allData.discountScales[fld.id]" :key="'d'+fldi+'.'+sci">
									
									<div class="selectable" :style="getAppStyle()+'cursor: pointer; display: inline-flex; width: 20%;'" 
									     @click="openEdit('DiscountScale', sc.id)">
										 <b v-if="fld.activeScaleId===sc.id">{{sc.name}}</b>
										 <span v-else>{{sc.name}}</span>
									 </div>
									<span class='scInp'><InputDouble @changed="saveSC(sc)" :clipboard="false" v-model="sc.fromBudget"/></span>
									<span class='scInp'><InputDouble @changed="saveSC(sc)"  disabled="true" :clipboard="false" v-model="sc.untilBudget"/></span>
									<span class='scInp'><InputDouble @changed="saveSC(sc)" :clipboard="false" v-model="sc.cashDiscount"/></span>
									<span class='scInp'><InputDouble @changed="saveSC(sc)" :clipboard="false" v-model="sc.incentiveDiscount"/></span>
									<span class='scInp'><InputDouble @changed="saveSC(sc)" :clipboard="false" v-model="sc.payPercentage"/></span>
									
									<Button class='button smallBN2' title="delete this discount-level" @click="removeScale(sc.id)"><mdicon size="18" name="close"/></button>
									<Button v-if="fld.activeScaleId===sc.id" title="de-activate this discount-level" class='button smallBN2' @click="activateScale(sc.id,-1)" style='background-color: #cfc; color: green;'>
										<mdicon size="18" name="check-bold"/>
									</button>
									<Button v-else title="activate this discount-level" class='button smallBN2' @click="activateScale(sc.id, 1)">
										<mdicon size="18" name="lock-open-variant-outline"/>
									</button>

										
								</div>
									
							</div>
							<div style='height: 5pt;'></div>
							</div>
				     </div>
				  </div>
			   </div>
				
			   
			</div>
			
	      </slot>
	      </div>
		  <div style='position: sticky; width: 100%; bottom: 22pt !important; height: 30pt; padding-right: 15pt;'>
		                   <Button style='float:right;' class='button btn-save' @click="save"><mdicon :size='16' name='content-save'/></button>
		  				   <Button title="reload data" style='float:right;' class='button' @click="loadData"><mdicon size="16" name="reload"/></button>
		                   <Button title="close popup" style='float:right;' class='button' @click="close"><mdicon :size='16' name='close'/></button>
		  				 
		  				 
		  				 <div style='float:right; display: flex; border: 1pt solid blue; margin-left: 8pt;'>&nbsp;</div>	
		  				 
		  				 

		                 
		  			</div>
	      </div>
	  </div>		
	  </XModal>
	  
	<GFWEOpenEditor ref='editor' @opened="hideMe" @closed="openMeAgain"></GFWEOpenEditor>
	<GConfirm ref='confirm'/>
	
</div>
</template>


<script>
import {HTTP, dealReportAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getFGColor } from '@/AppStyle.js';
import { getData, defer } from '@/utils.js';
import { format } from '@/Numbers.js';
import InputDatePick2 from '@/components/inputElements/InputDatePick3';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputMoney from '@/components/inputElements/InputMoneyNC';
import InputDouble from '@/components/inputElements/InputDouble';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GOPS_DealWizzard',
  components : {
      GConfirm, InputDatePick2,InputDouble, 'app-switch': Switch
	  //,InputMoney
  },
  props: {
    name: {
      type: String,
      default: 'DealWizzard'
    },
    height: {
      type: Number,
      default: 80
    }
  },
  data () {
    return {
		width: 60,
		deal: {},
		dealId: 0,
		client: {},
		agency: {},
		fldOpen: [],
		discountHeader: {},
		allData: null,
		visible: false,
		popupData: {},
		getAppStyle, format
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     
     title( name )
     {
    	return name;
     },
	 openFolder( id)
	 {
		if ( this.fldOpen.includes( id))
		{
			this.fldOpen = this.fldOpen.filter( p=>p!=id);
		}
		else
		{
			this.fldOpen.push( id);
		}
	 },
	 bg4Scale( fld, scale)
	 {
		if ( fld.activeScaleId===scale.id)
		{
			return "color: #fff; background-color: #000;";
		}
	 },
	 checkDrop( fld)
	 {
		let b = !this.allData.discountScales[fld.id] || !this.allData.discountScales[fld.id].length; 
		
		return b;
	 },
	 startDrag (evt, fld) {
	    evt.dataTransfer.dropEffect = 'copy'
	    evt.dataTransfer.effectAllowed = 'copy'
	    evt.dataTransfer.setData('from', fld.id)
	},
	onDrop(evt, fld) {
		 if (!this.checkDrop( fld))
		 {
			return false;
		 }
		 //alert( this.checkDrop( fld))
		 const source = evt.dataTransfer.getData('from');
		 let that = this;
		 let fldSource = this.allData.discountScaleFolders.find(p=>p.id==source);
		 this.$iosConfirm( "copy levels from "+fldSource.name+" to "+fld.name).then(x => { 
			
					HTTP.put( dealReportAPI+"/copyDiscountScale/"+sessionStorage.tenantId
						+"/"+sessionStorage.accessPointId
						+"/"+sessionStorage.unitId
						+"/"+sessionStorage.userId
						+"/"+that.dealId
						+"/"+source
						+"/"+fld.id
						)

					 .then( response => 
			         {
						let data = response.data;
						that.client = data.client;
						that.agency = data.agency;
						that.discountHeader = data.discountScaleHeader;
						that.allData = data;
				   		
					}).catch(e => {
			    	    showError( that.$toast, "create discount scale", e);
			        });
		 });
     },
	 addScale( folderId)
	 {
		let that = this;
		HTTP.put( dealReportAPI+"/createDiscountScale/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+that.dealId
			+"/"+folderId
			)

		 .then( response => 
         {
			let data = response.data;
			that.client = data.client;
			that.agency = data.agency;
			that.discountHeader = data.discountScaleHeader;
			that.allData = data;
	   		
		}).catch(e => {
    	    showError( that.$toast, "create discount scale", e);
        });
	 },
	 removeScale( scaleId)
 	 {
		let that = this;
		this.$iosConfirm( "remove selected discount-level").then(x => {
	 		
			
	 		HTTP.delete( dealReportAPI+"/removeDiscountScale/"+sessionStorage.tenantId
	 			+"/"+sessionStorage.accessPointId
	 			+"/"+sessionStorage.unitId
	 			+"/"+sessionStorage.userId
	 			+"/"+that.dealId
	 			+"/"+scaleId
	 			)
	
	 		 .then( response => 
	          {
	 			let data = response.data;
	 			that.client = data.client;
	 			that.agency = data.agency;
	 			that.discountHeader = data.discountScaleHeader;
	 			that.allData = data;
	 	   		
	 		}).catch(e => {
	     	    showError( that.$toast, "remove discount scale", e);
	         });
		});
 	 },
	 activateScale( scaleId, type)
  	 {
  		let that = this;
 		
  		HTTP.put( dealReportAPI+"/activateDiscountScale/"+sessionStorage.tenantId
  			+"/"+sessionStorage.accessPointId
  			+"/"+sessionStorage.unitId
  			+"/"+sessionStorage.userId
  			+"/"+that.dealId
  			+"/"+scaleId
			+"/"+type
  			)

  		 .then( response => 
           {
  			let data = response.data;
  			that.client = data.client;
  			that.agency = data.agency;
  			that.discountHeader = data.discountScaleHeader;
  			that.allData = data;
  	   		
  		}).catch(e => {
      	    showError( that.$toast, "remove discount scale", e);
          });
  	 },
	 saveFolder( fld)
   	 {
   		let that = this;
  		
   		HTTP.put( dealReportAPI+"/saveFolder/"+sessionStorage.tenantId
   			+"/"+sessionStorage.accessPointId
   			+"/"+sessionStorage.unitId
   			+"/"+sessionStorage.userId
   			+"/"+that.dealId,
 			fld
   			)

   		 .then( response => 
            {
   			let data = response.data;
   			that.client = data.client;
   			that.agency = data.agency;
   			that.discountHeader = data.discountScaleHeader;
   			that.allData = data;
   	   		
   		}).catch(e => {
       	    showError( that.$toast, "save folder", e);
           });
   	 },
	 removeFolder( folderId)
 	 {
 		let that = this;
		this.$iosConfirm( "remove selected discount-folder").then(x => {
	 		HTTP.delete( dealReportAPI+"/removeFolder/"+sessionStorage.tenantId
	 			+"/"+sessionStorage.accessPointId
	 			+"/"+sessionStorage.unitId
	 			+"/"+sessionStorage.userId
	 			+"/"+that.dealId
	 			+"/"+folderId
	 			)
	
	 		 .then( response => 
	          {
	 			let data = response.data;
	 			that.client = data.client;
	 			that.agency = data.agency;
	 			that.discountHeader = data.discountScaleHeader;
	 			that.allData = data;
	 	   		
	 		}).catch(e => {
	     	    showError( that.$toast, "remove discount scale", e);
	         });
		 });
 	 },	 
	 createDiscountHeader()
	 {
		let that = this;
		HTTP.put( dealReportAPI+"/createDiscountHeader/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+that.dealId
			)

		 .then( response => 
         {
			let data = response.data;
			that.client = data.client;
			that.agency = data.agency;
			that.discountHeader = data.discountScaleHeader;
			that.allData = data;
	   		
		}).catch(e => {
    	    showError( that.$toast, "create discount header", e);
        });
	 },
	 createDiscountFolder()
 	 {
 		let that = this;
 		HTTP.put( dealReportAPI+"/createDiscountFolder/"+sessionStorage.tenantId
 			+"/"+sessionStorage.accessPointId
 			+"/"+sessionStorage.unitId
 			+"/"+sessionStorage.userId
 			+"/"+that.dealId
 			)

 		 .then( response => 
          {
 			let data = response.data;
 			that.client = data.client;
 			that.agency = data.agency;
 			that.discountHeader = data.discountScaleHeader;
 			that.allData = data;
 	   		
 		}).catch(e => {
     	    showError( that.$toast, "create discount folder", e);
         });
 	 },
	 openEdit( entity, id )
	 {
		this.$refs.editor.open( entity, id);
	 },
	 openMeAgain()
	 {
		this.visible = true;
	 },
	 hideMe()
	 {
	 	this.visible = false;
	 },
	 open(popupData) {
		
	    this.popupData = popupData;
		this.allData = null;
		let that = this;
		that.dealId = popupData.dealId ? popupData.dealId : popupData.possibleDealId;
		getData('Deal', that.dealId).then( d=>{
			that.deal = d;
			that.loadData().then(p=>{
				that.$nextTick(function () {
				   	try {
				   		that.$modal.show(that.name);
						that.visible = true;
				   		setTimeout( 500, function() { that.$forceUpdate()});
				   	} catch (e) {
				   	//
				    }
				})
			});
			
		});
	 },
	 loadData()
	 {
		let that = this;
		let promDefer = defer();
		HTTP.post( dealReportAPI+"/getDealData/"+sessionStorage.tenantId
   				+"/"+sessionStorage.accessPointId
   				+"/"+sessionStorage.unitId
   				+"/"+sessionStorage.userId
   				+"/"+that.dealId
   				)

    		 .then( response => 
             {
				let data = response.data;
				that.client = data.client;
				that.agency = data.agency;
				that.discountHeader = data.discountScaleHeader;
				that.allData = data;
				promDefer.resolve( data)
			}).catch(e => {
				promDefer.reject();
        	    showError( that.$toast, "get deal data", e);
            });
	    return promDefer;
	 },
     getTableStyle() {
        
     	//return "overflow-y: scroll; height: calc(60vH - 104px)";
     	return "overflow-y: auto; height: calc("+this.height+"vH - 180pt) !important;";
     },
	 save( )
 	 {
		let that = this;
		HTTP.put( dealReportAPI+"/updateDealStructure/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+that.dealId,
			that.allData
			)

		 .then( response => 
         {
			let data = response.data;
			that.client = data.client;
			that.agency = data.agency;
			that.discountHeader = data.discountScaleHeader;
			that.allData = data;
	   		
		}).catch(e => {
    	    showError( that.$toast, "update discount scale", e);
        });
 	 },
	 saveSC( sc)
	 {
		console.log( sc);
		let that = this;
		HTTP.put( dealReportAPI+"/updateDiscountScale/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+that.dealId
			+"/"+sc.folderId,
			sc
			)

		 .then( response => 
         {
			let data = response.data;
			that.client = data.client;
			that.agency = data.agency;
			that.discountHeader = data.discountScaleHeader;
			that.allData = data;
	   		
		}).catch(e => {
    	    showError( that.$toast, "update discount scale", e);
        });
	 },
	 
  },
  watch: {

  },
  created() {

  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  overflow-y: hidden;
}
.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 80% !important;
  
  float: top;
} 
.selectable {
	cursor: pointer; 
	transition: all 0.4s ease;
}
.bold {
	font-weight: bold;
}
.indent1 {
	margin-left: 10pt;
}
.indent2 {
	margin-left: 20pt;
}
.border {
	border: 1px solid #ccc;
}
.selectable:hover {
	background-color: #ccc !important;
	color: #000 !important;
}
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.btn-save {
	background-color: #080 !important;
    color: #fff !important;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.7em - 1px);
    padding-left: .7em;
    padding-right: .7em;
    padding-top: calc(0.7em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.smallBN {
	//width: 18%;
    outline: none;
    border-radius: 3px;
    height: 14pt;
    width: 18pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 2pt;
	margin-top: 2pt;
} 
.smallBN2 {
	//width: 18%;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    width: 20pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 2pt;
	margin-top: 2pt;
} 
.smallStep {
	
    outline: none;
    border-radius: 3px;
    height: 14pt;
    width: 18pt;
    font-size: 9pt;
    background-color: #cfc;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
	margin-left: 5pt;
	padding-top: 0pt;
	margin-top: 2pt;
}  
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.steps {
	margin-left: 5pt;
}
.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.scInp {
	display: inline-flex;
	width: 12% !important;	
}
</style>
